<template>
  <div class="height1">
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <el-table :data="tableData" stripe height="calc(100% - 10px)" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column type="index" label="编号" width="80px"></el-table-column>
          <el-table-column prop="name" label="机构信息"> 
            <template slot-scope="scope">
              <p>{{ scope.row.name }}</p>
              <p>{{ scope.row.phone }}</p>
              <p>联系人：{{ scope.row.contactName }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <span class="warning optionBtn" @click="toPage(scope.row,'IncomeStat')">门诊收入统计</span>
              <span class="danger optionBtn" @click="toPage(scope.row,'RefundStat')">门诊退费统计</span>
              <span class="primary optionBtn" @click="toPage(scope.row,'DrugSalesStat')">药品销售统计</span>
              <span class="success optionBtn" @click="toPage(scope.row,'DoctorSalesStat')"> 医生业绩统计</span>
              <span class="warning optionBtn" @click="toPage(scope.row,'OtherStat')">其它费用统计</span>
              <span class="danger optionBtn" @click="toPage(scope.row,'ReviewerFeesStat')">审方费用统计</span>
              <span class="primary optionBtn" @click="toPage(scope.row,'DepatmentStat')">部门费用统计</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import bread from '@/components/common/breadcrumb'
import { Organization } from '@/components/HospitalDomain/Organization'
var timer = ''
export default {
  name: 'Order',
  components: {
    bread,
  },
  data() {
    var organization = new Organization(this.TokenClient, this.Services.Authorization)
    return {
      OrganizationDomain: organization,
      tableData: [],
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
    }
  },
  mounted() {
    this.GetList()
  },
  methods: {
    toPage(item,str) {
      this.$router.push({
        path: `./Org/${str}`,
        query: {
          orgId: item.id,
        },
      })
    },
    GetList() {
      var _this = this
      _this.OrganizationDomain.GetOrganizationListById(
        _this.userInfo.organizationId,
        function (data) {
          _this.tableData = data.data
        },
        function (error) {
          console.log(error)
        }
      )
    },
  },
}
</script>

